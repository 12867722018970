import { LOGGER_NAME, getLogger } from '../logger.js'
import splitFactoryConfig from './split-factory-config'

let splitClient

const getFactory = async (splitKey, userID, tenantID, splitSDKSettings) => {
  if (!userID || !tenantID) {
    throw new Error('There is no user or customer')
  }
  const options = splitFactoryConfig(splitKey, tenantID, splitSDKSettings)
  const { SplitFactory } = await import('@splitsoftware/splitio')
  const factory = SplitFactory(options)
  return factory
}

const getClient = async (factory) => {
  let client
  try {
    client = factory.client()
    await new Promise((resolve, reject) => {
      client.once(client.Event.SDK_READY, () => resolve())
      client.once(client.Event.SDK_READY_TIMED_OUT, () =>
        reject('SKD_READY_TIMED_OUT event fired'),
      )
    })
  } catch (e) {
    const logger = await getLogger()
    logger.warn(
      `${LOGGER_NAME}: Timeout expired while creating Split client; no impressions will be logged.`,
    )
  }
  setClient(client)
  return splitClient
}

const setClient = (client) => {
  splitClient = client
}

/**
 * Destroys the Split client.
 * This only happens when the user changes within a session
 * (which is handled within the auth utility).
 * Microfrontends should never need to use this.
 * @returns {Promise<void>}
 */
export async function destroy() {
  if (!splitClient) {
    return
  }
  await splitClient.destroy()
  splitClient = null // eslint-disable-line require-atomic-updates
}

/**
 * Initialize the Split client to retrieve split values.
 * This is done during the page bootstrap.
 * Microfrontends should never need to use this.
 * @param {string} splitKey
 * @param {string} userID
 * @param {string} tenantID
 * @param {import("@splitsoftware/splitio").IBrowserSettings} splitSDKSettings
 * @returns {Promise<undefined>}
 */
export async function init(splitKey, userID, tenantID, splitSDKSettings) {
  // If window.splitKey is undefined we want to warn the developer and initialize split with an empty array
  if (!splitKey) {
    return undefined
  }

  try {
    const factory = await getFactory(
      splitKey,
      userID,
      tenantID,
      splitSDKSettings,
    )
    const [client] = await Promise.race([
      Promise.all([getClient(factory)]),
      new Promise((resolve, reject) => {
        // Fallback to make sure that if split fails to throw an error we will manually throw after 5s
        setTimeout(() => reject('MANUAL TIME OUT FIRED'), 5000)
      }),
    ])
    client.setAttributes({
      ...(userID && {
        userID,
      }),
      tenantID,
    })
    return client
  } catch (e) {
    const logger = await getLogger()
    logger.warn(`${LOGGER_NAME}: ${e.message}`)
  }
}

/*
 * This appears to be required in order to prevent the SPLIT SDK from reinitializing (incorrectly) on a refresh
 * https://help.split.io/hc/en-us/articles/360026942812-JavaScript-SDK-Implementation-and-Troubleshooting
 * https://youtu.be/JDDnkpNyN24?t=273
 */
window?.addEventListener('beforeunload', () => {
  destroy()
})
