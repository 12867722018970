import { getAllTreatments$, maybeLogImpression } from './utilities'

// TODO: After completing RxJS 6 -> 7 migration come back here and look at using a Behavior Subject instead so we don't have to subscribe internally.
let treatments = {}
let isTreatmentsInitialized = false

getAllTreatments$().subscribe((t) => {
  treatments = t
  isTreatmentsInitialized = true
})

/**
 * Returns the current treatment for a split.
 * Returns null if Split has no value for that treatment.
 * @param {string} split  split name
 * @returns {string | undefined | null}
 */
export function useTreatment(split) {
  if (!isTreatmentsInitialized) {
    return undefined
  }
  maybeLogImpression(split)
  return treatments[split]?.treatment || null
}

/**
 * Returns a boolean value representing whether that treatment
 * is the compareTo value, which is 'on' by default
 * @param {string} split  split name
 * @param {string} [compareTo]  a value to compare split against
 * @returns {boolean}
 */
export function useIsTreatmentEnabled(split, compareTo = 'on') {
  const treatmentValue = useTreatment(split)
  return treatmentValue === compareTo
}

/**
 * Returns the treatment's configuration object or null
 * @param {string} split  split name
 * @returns {Object | undefined}
 */
export function useTreatmentConfig(split) {
  const treatmentConfig = treatments?.[split]?.config
  return treatmentConfig && JSON.parse(treatmentConfig)
}
