export const LOGGER_NAME = 'Toggles'

let logger

export async function getLogger() {
  if (logger) {
    return logger
  }
  const { createMFELogger } = await System.import('@wf-mfe/logger')
  logger = createMFELogger({ name: '@wf-mfe/toggles' })
  return logger
}
